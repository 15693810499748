<template>
    <template-base>
        <tabs :tabs="tabs" :disabled="tabsDisabled">
            <template #default="{ tab }">
                <alarmes-online-list @atualizando="atualizando" :filtros="tab.filtros"/>
            </template>
        </tabs>
    </template-base>
</template>

<script>
import TemplateBase from '../templates/Base'
import Tabs from '../components/Tabs'

import axios from 'axios'
import api from '@/api.js'

import AlarmesOnlineList from './AlarmesOnline/List'

const filters = () => [{
    nameAll: 'Selecionar todas',
    nameNotAll: 'Deselecionar todas',
    func() {
        return true;
    },
}];

export default {
    components: {
        TemplateBase,
        AlarmesOnlineList,
        Tabs,
    },
    data() {
        return {
            tabs: [
                { isActive: true, name: 'Geral', filtros: {} },
                { isActive: false, name: 'Alarmes de vazamento de gás', filtros: { severidade: 'Alarme', classeAlarme: 'Vazamento de gás' } },
                { isActive: false, name: 'Alertas de vazamento de gás', filtros: { severidade: 'Alerta', classeAlarme: 'Vazamento de gás' } },
                { isActive: false, name: 'Alarmes de dispositivo', filtros: { severidade: 'Alarme', classeAlarme: 'Dispositivo' } },
                { isActive: false, name: 'Alertas de dispositivo', filtros: { severidade: 'Alerta', classeAlarme: 'Dispositivo' } },
                { isActive: false, name: 'Calibração', filtros: { classeAlarme: 'Calibração' } },
                { isActive: false, name: 'Manutenção', filtros: { classeAlarme: 'Manutenção' } },
                { isActive: false, name: 'Reconhecidos', filtros: { reconhecido: true } },
            ],
            errMsg: '',
            loading: true,
            tabsDisabled: false,
        }
    },
    methods: {
        atualizando(att) {
            this.tabsDisabled = att;
        },
    }
}
</script>
<style scoped>
    label {
        display: block;
    }
</style>
