<template>
    <div>
         <div class="d-flex justify-content-between mb-2">
            <div>
                <button class="btn btn-secondary config" @click="toggle" data-cy="Alterar colunas"><SettingsIcon size="16"/> Alterar colunas</button>
            </div>
            <div>
                <span v-if="updateTimer" class="mr-2 text-muted">Atualizando em {{updateTimer}} segundos...</span>
                <span v-else class="mr-2 text-muted">Atualizando...</span>
                <button :disabled="!updateTimer" class="btn btn-secondary" @click="updateTimer = 0">Atualizar agora</button>
            </div>
        </div>
        <vue-slide-up-down :active="!retracted">
            <div class="mt-3 mb-2 seletor">
                <h5> Habilitar/desabilitar colunas </h5>
                <div class="seletor__colunas">
                    <div v-for="(value,index) in chosenCols" :key="index" class="custom-control custom-checkbox">
                        <input :disabled="index === 0" type="checkbox" class="custom-control-input" :id="`i_${cols[index]}`" v-model="chosenCols[index]" :data-cy="cols[index]">
                        <label class="custom-control-label" :for="`i_${cols[index]}`">{{cols[index]}}</label>
                    </div>
                </div>
            </div>
        </vue-slide-up-down>
        <div>
            <DataTable async nosearch scroll noedit condensed :loading="loading" :colunas="parsedCols" :linhas="parsedLinhas" :errMsg="errMsg" :state="state" @state-change="setStateDataTable">
                <template #Detector="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Concentrador="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Conversor="{ value }">
                    <alert-triangle-icon size="16" v-if="value[1]" /> {{value[0] || '-'}}
                </template>
                <template #Área="{ value }">
                    <AreaHierarquica :nome="value[0]" :id="value[1]" />
                </template>
            </DataTable>

            <!------------------------------------------- Legenda das cores da tabela -------------------------------------------->
            <div class="legenda">
                <div>
                    <div class="rectangle" style="background-color: #d32f2f;"></div><span style="margin-right: 10px;"> Alarme de vazamento de gás </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #ffeb3b;"></div><span style="margin-right: 10px;"> Alerta de vazamento de gás </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #0277bd;"></div><span style="margin-right: 10px;"> Alarme de dispositivo </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #81d4fa;"></div><span style="margin-right: 10px;"> Alerta de dispositivo </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #ff8f00;"></div><span style="margin-right: 10px;"> Calibração </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #7b1fa2;"></div><span style="margin-right: 10px;"> Manutenção </span>
                </div>
                <div>
                    <div class="rectangle" style="background-color: #757575;"></div><span> Reconhecido </span>
                </div>
            </div>
            <!--------------------------------------------------- Fim da legenda -------------------------------------------------->
        </div>
    </div>
</template>
<script>
import DataTable from '@/components/DataTable'
import axios from 'axios'
import api from '@/api'
import AreaHierarquica from '@/components/AreaHierarquica'
import VueSlideUpDown from 'vue-slide-up-down'
import loginService from '@/services/login'

let updateInterval;

function ms2IntervalStr (dur) {
    let duracao = dur;
    let duracaoStr = '';
    if (duracao > 0) {
        let horas = parseInt(duracao / (60 * 60 * 1000));
        duracao -= horas * (60 * 60 * 1000);
        let minutos = parseInt(duracao / (60 * 1000));
        duracao -= minutos * (60 * 1000);
        let segundos = parseInt(duracao / 1000);
        duracaoStr = `${(''+horas).padStart(2, '0')}:${(''+minutos).padStart(2, '0')}:${(''+segundos).padStart(2, '0')}`
    }
    return duracaoStr;
}
const classesStyles = {
    'Alarme de Vazamento de gás': {
        backgroundColor: '#d32f2f',
        color: 'white',
    },
    'Alerta de Vazamento de gás': {
        backgroundColor: '#ffeb3b',
        color: 'black',
    },
    'Alarme de Dispositivo': {
        backgroundColor: '#0277bd',
        color: 'white',
    },
    'Alerta de Dispositivo': {
        backgroundColor: '#81d4fa',
        color: 'black',
    },
    'Alerta de Calibração': {
        backgroundColor: '#ff8f00',
        color: 'black',
    },
    'Alerta de Manutenção': {
        backgroundColor: '#7b1fa2',
        color: 'white',
    },
    'Reconhecido': {
        backgroundColor: '#757575',
        color: 'white',
    },

}
export default {
    components: {
        DataTable,
        AreaHierarquica,
        VueSlideUpDown,
    },
    props: {
        filtros: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            lista: [],
            colsName: ['id','dataHoraInicial','nomeArea', 'nomeDetector','nomeConcentrador', 'nomeConversor', 'classeAlarme', 'severidade','descricao', 'extra', 'dataHoraReconhecimento', null, null, null],
            cols: ['TAG','Data/hora inicial', 'Área', 'Detector', 'Concentrador', 'Conversor', 'Classe de alarme', 'Tipo do alarme', 'Descrição', 'Extra', 'Data/hora reconhecimento', 'Usuário', 'Contato', 'Tempo de reconhecimento'],
            chosenCols: Array(14).fill(true),
            page: 0,
            pages: 1,
            results: 20,
            first: 0,
            last: 0,
            count: 0,
            loading: false,
            errMsg: '',
            retracted: true,

            state: {
                sortBy: 1,
                sortAsc: false,
                query: ''
            },

            updateTimer: 10,
        }
    },
    computed: {
        parsedCols() {
            return this.cols.map(c => ({ value: c, sortable: false }))
            .filter((el, ind) => this.chosenCols[ind]);
        },
        parsedLinhas() {
            return this.lista.map(row => ({
                ...row,
                cols: row.cols.filter((el, ind) => this.chosenCols[ind])
            }));
        },
        parsedColsName() {
            return this.colsName.filter((el, ind) => this.chosenCols[ind]);
        }
    },
    methods: {
        toggle() {
            this.retracted = !this.retracted;
        },
        downloadRelatorio() {
            let a = document.createElement('a');
            a.href = `${api.v1.alarme.download(JSON.stringify(this.filtros))}&bearer=${loginService.getBearerToken()}`;
            a.target = '_blank';
            a.click();
        },
        setStateDataTable (state) {
            this.state.sortBy = state.sortBy;
            this.state.sortAsc = state.sortAsc;
            this.page = 0;
            this.update(1, this.results);
        },
        changeResults (event) {
            this.page = 0;
            this.update(1, event.target.value);
        },
        paginate (p) {
            if (p === this.page) return;
            this.page = p;
            this.update(this.page, this.results);
        },
        update (page = 1, results = -1) {
            this.$emit('atualizando', true);
            let url = api.v1.alarme.online(page, results, JSON.stringify(this.filtros), this.parsedColsName[this.state.sortBy], this.state.sortAsc);
            return axios.get(url).then(res => {
                if (res.data.rows) { // paginação ativada
                    this.page = res.data.page;
                    this.pages = res.data.pages;
                    this.first = res.data.first;
                    this.last = res.data.last;
                    this.count = res.data.count;
                    this.results = res.data.results;
                }
                this.lista = (res.data.rows || res.data).map(i => {
                    let inicio = new Date(i.dataHoraInicial);
                    let rec = i.dataHoraReconhecimento ? new Date(i.dataHoraReconhecimento) : '';
                    let duracaoReconhecimento = ms2IntervalStr(rec - inicio);
                    return {
                        color: i.dataHoraReconhecimento ? classesStyles['Reconhecido']?.color : classesStyles[`${i.severidade} de ${i.classeAlarme}`]?.color,
                        backgroundColor: i.dataHoraReconhecimento ? classesStyles['Reconhecido']?.backgroundColor : classesStyles[`${i.severidade} de ${i.classeAlarme}`]?.backgroundColor,
                        cols: [i.id, inicio.toLocaleString(), [i.nomeArea, i.idArea], [i.nomeDetector, i.idDetector], [i.nomeConcentrador, i.idConcentrador], [i.nomeConversor, i.idConversor], i.classeAlarme, i.severidade, i.descricao, i.extra, rec.toLocaleString(), i.usuarioReconhecimento?.nome, i.usuarioReconhecimento?.email, duracaoReconhecimento]
                    };
                });
                this.errMsg = '';
            }).catch(reason => {
                this.lista = [];
                this.errMsg = reason?.response?.data ?? reason.toString();
            }).then(() => {
                this.$emit('atualizando', false);
            });
        },
        async updateLoading() {
            this.loading = true;
            await this.update();
            this.loading = false;
        }
    },
    watch: {
        filtros() {
            this.updateTimer = 0;
        },
        chosenCols() {
            if (this.chosenCols[this.state.sortBy] === false) {
                this.state.sortBy = 0;
                this.updateLoading();
            }
        },
    },
    created () {
        this.updateLoading();
        const timer = this.updateTimer;
        let updating = false;
        updateInterval = setInterval(() => {
            if (this.updateTimer > 0) {
                this.updateTimer--;
            } else if (updating === false) {
                updating = true;
                this.update().then(() => {
                    updating = false;
                    this.updateTimer = timer;
                });
            }
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(updateInterval);
    }
}
</script>
<style scoped>
    .rectangle {
        height: 10px;
        width: 25px;
        display: inline-block;
    }
    .legenda {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        position: sticky;
        background-color: white;
        margin: 10px;
        bottom: 10px;
        border-radius: 5px;
        padding: 5px 0;
        border-radius: 1px solid #333;
        box-shadow: 0 1px 3px #333;
    }
    .seletor {
        border: 1px solid #333;
        border-radius: 10px;
        padding: 1rem;
    }
    .seletor__colunas {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    }

    @media (max-width: 500px) {
    .d-flex.justify-content-between.mb-2 {
        font-size: 13px;
    }
    .btn.btn-secondary {
        font-size: 13px;
    }

    .btn.btn-secondary.config {
        font-size: 0px;
        height: 100%;
    }
}
</style>
